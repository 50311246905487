import { graphql, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import { HeroSwiper } from "../components/heroSwiper";
import { TeamPageQuery } from "../generated/graphql";
import Shell from "../layout/shell";

import blackArrow from "../assets/images/icon-rightarrow-black.svg";

import * as styles from "./team.module.scss";

const Team: React.FC<PageProps<TeamPageQuery>> = (pageProps) => {
  const data = pageProps.data.prismicTeamPage?.data;
  if (!data) {
    return null;
  }
  return (
    <Shell>
      <div className="fixed-width">
        <h1>{data.title}</h1>
      </div>
      <hr />
      <div className="fixed-width">
        <HeroSwiper>
          {data.hero_gallery?.map((img) => (
            <GatsbyImage
              image={img?.image?.gatsbyImageData}
              alt={img?.image?.alt || "Hero image"}
            />
          ))}
        </HeroSwiper>
        <p className="intro">{data.intro_copy}</p>
        {data.team_entries?.map((te) => (
          <div className={styles.teamListItem}>
            <img src={blackArrow} alt="black arrow" />
            <div>
              <h2>{te?.team_title}</h2>
              <p>{te?.team_copy}</p>
              {te?.team_website_link && (
                <a href={te.team_website_link.url || "#"}>{te.team_website_link.url?.replace("https://", "").replace("/", "")}</a>
              )}
            </div>
          </div>
        ))}
      </div>
    </Shell>
  );
};

export const query = graphql`
  query TeamPage {
    prismicTeamPage {
      data {
        intro_copy
        title
        hero_gallery {
          image {
            gatsbyImageData
            alt
          }
        }
        team_entries {
          team_title
          team_copy
          team_website_link {
            url
          }
        }
      }
    }
  }
`;

export default Team;
